




























import Page from '@/components/swine/layout/Page.vue';
import GeneralPage from '@/components/general/layout/GeneralPage.vue';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import UserFormTemplate from './UserFormTemplate.vue';
import { UserStores } from '@/store/swine/user/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { ToastProgrammatic } from 'buefy';
import { Role } from '@/utils/constants';

@Component({
  components: {
    Page,
    InnerPageTemplate,
    UserFormTemplate,
    GeneralPage,
  },
})
export default class CreateUser extends Vue {
  store = UserStores.create;
  isEmailAlreadyUsed = false;

  updateRole(params: DropdownOption) {
    this.store.updateRole(params);
    if (params.value === Role.Regional) {
      this.store.updateCountry('');
    }
  }

  updatePhone(params: string) {
    this.store.updatePhone(params);
  }

  updateName(params: string) {
    this.store.updateName(params);
  }

  updateEmail(params: string) {
    this.store.updateEmail(params);
  }

  updatePassword(params: string) {
    this.store.updatePassword(params);
  }

  updateCountry(params: string) {
    this.store.updateCountry(params);
  }

  updateAuditType(params: number[]) {
    this.store.updateAuditType(params);
  }

  updateModuleType(params: number[]) {
    this.store.updateModuleType(params);
  }

  createUser() {
    this.isEmailAlreadyUsed = false;
    this.store.createUser();
  }

  @Watch('store.response')
  checkPostResponse() {
    if (
      this.store.response.message &&
      this.store.response.message.includes(`failed with status code 409`)
    ) {
      this.isEmailAlreadyUsed = true;
      this.store.updateEmail('');
    }

    if (this.store.response && this.store.response.status === 201) {
      this.store.resetStore();
      ToastProgrammatic.open({
        type: `is-dark`,
        duration: 3000,
        message: `User is successfully created`,
      });

      this.$router.push({
        name: 'UserManagement',
      });
    }
  }

  get buttonDisabled() {
    if (this.store.userDetails.role === Role.Regional) {
      return (
        this.store.userDetails.name === '' ||
        this.store.userDetails.phoneNumber === '' ||
        this.store.userDetails.email === '' ||
        this.store.userDetails.password === '' ||
        this.store.userDetails.auditTypeIds!.length === 0 ||
        this.store.userDetails.moduleTypeIds!.length === 0
      );
    }
    return (
      this.store.userDetails.name === '' ||
      this.store.userDetails.phoneNumber === '' ||
      this.store.userDetails.email === '' ||
      this.store.userDetails.password === '' ||
      this.store.userDetails.role === '' ||
      this.store.userDetails.countryId === '' ||
      this.store.userDetails.auditTypeIds!.length === 0 ||
      this.store.userDetails.moduleTypeIds!.length === 0
    );
  }
}
